import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/MainLayout.vue'
import LoginLayout from '../views/layout/LoginLayout.vue'
import supplierLayout from '../views/layout/supplierLayout.vue'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { getUser, getSupplierId } from '../utils/auth.js'
import { handlers } from '../helpers/custom'
import services from '../helpers/services'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Layout,
      redirect: '/homePage',
      children: [
        {
          path: '/homePage',
          name: 'homePage',
          component: () => import('../views/pages/homePage.vue')
        },
        {
          path: '/home',
          name: 'home',
          component: () => import('../views/pages/home/Home.vue')
        },
        {
          path: '/sales-report',
          name: 'salesReport',
          component: () => import('../views/pages/salesReport/salesReport.vue')
        },
        {
          path: '/cancel-report',
          name: 'cancelReport',
          component: () => import('../views/pages/cancelReport/cancelReport.vue')
        },
        {
          path: '/remainder-report',
          name: 'remainderReport',
          component: () => import('../views/pages/remainderReport/remainderReport.vue')
        },
        {
          path: '/remainder',
          name: 'remainder',
          component: () => import('../views/pages/remainder/remainderList.vue')
        },
        {
          path: '/client-contact-edit/:employee_id',
          name: 'clientContactEdit',
          component: () => import('../views/pages/clientContact/clientContactEdit.vue')
        },
        {
          path: '/client-contact-add',
          name: 'clientContactAdd',
          component: () => import('../views/pages/clientContact/clientContactAdd.vue')
        },
        {
          path: '/client-contact-list',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'clientContactList',
          component: () => import('../views/pages/clientContact/clientContactList.vue')
        },
        {
          path: '/contact-list',
          name: 'contactList',
          component: () => import('../components/contactList.vue')
        },
        {
          path: '/brandList',
          name: 'brandList',
          component: () => import('../views/pages/brand/brandList.vue')
        },
        {
          path: '/brand-add',
          name: 'brandAddForm',
          component: () => import('../views/pages/brand/brandAddForm.vue')
        },
        {
          path: '/edit-brand/:brand_id',
          name: 'editBrand',
          component: () => import('../views/pages/brand/brandUpdateForm.vue')
        },
        {
          path: '/add-warehouse',
          name: 'addWarehouse',
          component: () => import('../views/pages/warehouse/warehouseForm.vue')
        },
        {
          path: '/inventory',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'inventory',
          component: () => import('../views/pages/inventory/stockList.vue')
        },
        {
          path: '/supplier-list',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'supplierList',
          component: () => import('../views/pages/suppliers/supplierList.vue')
        },
        {
          path: '/supplier-edit',
          name: 'supplierEdit',
          component: () => import('../views/pages/suppliers/supplierEdit.vue')
        },
        {
          path: '/stock-detail/:variant_id',
          name: 'stockDetail',
          component: () => import('../views/pages/inventory/stockDetail.vue')
        },
        {
          path: '/products',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'products',
          component: () => import('../views/pages/product/productList.vue')
        },
        {
          path: '/update-product/:product_id',
          name: 'updateProduct',
          component: () =>
            import('../views/pages/product/productUpdateForm.vue')
        },
        {
          path: '/add-product',
          name: 'addProduct',
          component: () => import('../views/pages/product/productForm.vue')
        },
        {
          path: '/transfer',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'transfer',
          component: () => import('../views/pages/transfer/transferList.vue')
        },
        {
          path: '/add-transfer',
          name: 'addTransfer',
          component: () => import('../views/pages/transfer/transferForm.vue')
        },
        {
          path: '/edit-transfer/:transfer_id',
          name: 'editTransfer',
          component: () => import('../views/pages/transfer/editTransfer.vue')
        },
        {
          path: '/transferDetail/:transfer_id',
          name: 'transferDetail',
          component: () => import('../views/pages/transfer/transferDetail.vue')
        },
        {
          path: '/return',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'return',
          component: () => import('../views/pages/return/returnList.vue')
        },
        {
          path: '/add-return',
          name: 'addReturn',
          component: () => import('../views/pages/return/returnForm.vue')
        },
        {
          path: '/edit-return/:transfer_id',
          name: 'editReturn',
          component: () => import('../views/pages/return/editReturn.vue')
        },
        {
          path: '/returnDetail/:transfer_id',
          name: 'returnDetail',
          component: () => import('../views/pages/return/returnDetail.vue')
        },
        {
          path: '/receive-return-detail/:transfer_id',
          name: 'receiveReturnDetail',
          component: () =>
            import('../views/pages/return/receiveReturnDetail.vue')
        },
        {
          path: '/receive-transfer-detail/:transfer_id',
          name: 'receiveTransferDetail',
          component: () =>
            import('../views/pages/transfer/receiveTransferDetail.vue')
        },
        {
          path: '/order',
          props: route => ({ page: route.query.page, size: route.query.size }),
          name: 'orderList',
          component: () => import('../views/pages/order/orderList.vue')
        },
        {
          path: '/order-collect',
          name: 'orderCollect',
          component: () => import('../views/pages/order/orderCollect.vue')
        },
        {
          path: '/add-order',
          name: 'addOrder',
          component: () => import('../views/pages/order/addOrder.vue')
        },
        {
          path: '/order-detail/:id',
          name: 'orderDetail',
          component: () => import('../views/pages/order/orderDetail.vue')
        },
        {
          path: '/add-price',
          name: 'addPrice',
          component: () => import('../views/pages/priceApproval/addPrice.vue')
        },
        {
          path: '/schedule',
          name: 'Schedule',
          component: () => import('../views/pages/Schedule/Schedule.vue')
        },
        {
          path: '/warehouse',
          name: 'Warehouse',
          component: () => import('../views/pages/warehouse/warehouseList.vue')
        },
        {
          path: '/add-warehouse',
          name: 'AddWarehouse',
          component: () => import('../views/pages/warehouse/warehouseForm.vue')
        },
        {
          path: '/update-warehouse/:warehouse_id',
          name: 'UpdateWarehouse',
          component: () => import('../views/pages/warehouse/warehouseUpdate.vue')
        },
        {
          path: '/create',
          component: supplierLayout,
          meta: { requiresAuth: true },
          redirect: '/create',
          children: [
            {
              path: '/create',
              name: 'create',
              component: () => import('../views/pages/supplier/create.vue')
            }
          ]
        }
      ],
      meta: { requiresAuth: true }
    },
    {
      path: '/signIn',
      component: LoginLayout,
      redirect: '/signIn',
      meta: { requiresAuth: false },
      children: [
        {
          path: '/signIn',
          name: 'signIn',
          component: () => import('../views/pages/user/login.vue'),
          meta: { requiresAuth: false }
        },
        {
          path: '/signUp',
          name: 'signUp',
          component: () => import('../views/pages/user/signUp.vue'),
          meta: { requiresAuth: false }
        },
        {
          path: '/verify/:username',
          name: 'verify',
          component: () => import('../views/pages/user/verify.vue'),
          meta: { requiresAuth: false }
        },
        {
          path: '/forgot-password',
          name: 'forgotPassword',
          component: () => import('../views/pages/user/forgotPassword.vue'),
          meta: { requiresAuth: false }
        },
        {
          path: '/change-password/:username',
          name: 'changePassword',
          component: () => import('../views/pages/user/changePassword.vue'),
          meta: { requiresAuth: false }
        }
      ]
    },
    {
      path: '*',
      component: () => import('../views/pages/404.vue')
    }
  ]
})

AmplifyEventBus.$on('authState', async state => {
  const pushPathes = {
    signedOut: () => {
      router.push({ path: '/signIn' })
    },
    signUp: () => {
      router.push({ path: '/signUp' })
    },
    // confirmSignUp: () => {
    //   router.push({ path: '/signUpConfirm' })
    // },
    signIn: () => {
      router.push({ path: '/signIn' })
    },
    signedIn: async () => {
      const supplierIds = await getSupplierId()
      if (!supplierIds) {
        router.push({ path: '/create' })
      } else {
        router.push({ path: '/homePage' })
      }
    }
  }
  if (typeof pushPathes[state] === 'function') {
    pushPathes[state]()
  }
})

router.beforeResolve(async (to, from, next) => {
  const user = await getUser()
  const supplierIds = await getSupplierId()
  if (!user) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      return next({
        path: '/signIn'
      })
    }
  } else {
    const query = '?search_text=' + '' + '&size=' + 1000 + '&from=' + 0
    services.getBrand(query).then(response => {
      if (!supplierIds && to.path !== '/create') {
        return next({
          path: '/create'
        })
      } else if (response.data.length === 0 && localStorage.getItem('brandCount') && localStorage.getItem('brandCount') === '0' && to.path !== '/create') {
        handlers.alertReporter('Уучлаарай та брэндээ үүсгэнэ үү', 'warning')
        return next({ path: '/create?activeStep=1', query: { supplier_id: supplierIds.length > 0 ? supplierIds[0] : '' } })
      } else {
        return next()
      }
    })
  }
  return next()
})

export default router
